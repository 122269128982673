<template>
  <div
    v-if="data && data.recommendations.length"
    id="article-recommendation"
    class="article-layout-sidebar-item"
  >
    <div class="sidebar-item-content">
      <div class="sidebar-label">
        {{ $texts('book_recommendation', 'Buchtipp') }}
      </div>
      <div class="recommendations">
        <BookRecommendationItem
          v-for="recommendation in data.recommendations"
          :key="recommendation.nid"
          v-bind="recommendation"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  nodeId: {
    type: Number,
    default: 0,
  },
  termIds: {
    type: String,
    default: '',
  },
})

const { $texts } = useNuxtApp()

// Get the data.
const { data } = await useAsyncData(async () => {
  const variables = {
    nid: props.nodeId?.toString(),
    tid: props.termIds,
  }
  return useGraphqlQuery('bookRecommendations', variables).then((v) => {
    return {
      //@ts-ignore
      recommendations: v.data?.entityById?.executable?.execute?.rows || [],
    }
  })
})
</script>
