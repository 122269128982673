<template>
  <main>
    <header class="hero">
      <div class="container">
        <div class="hero-content">
          <h1 class="h1">{{ name }}</h1>
          <div v-if="biography" class="content" v-html="biography"></div>
          <p v-else class="lead">{{ $texts('author', 'Autor') }}</p>
        </div>
      </div>
    </header>
    <div class="page-content">
      <Container>
        <div class="row">
          <div class="col-lg-8 col-12">
            <div class="snippet-list">
              <template v-if="pending">
                <snippet-loading v-for="index in 10" :key="'loading' + index" />
              </template>
              <template v-else>
                <snippet
                  v-for="article in data.articles"
                  :key="article.url"
                  :title="article.title"
                  :to="article.url"
                  :passages="article.bible_passage"
                  :text="article.snippet"
                  :type="article.bundle"
                  :type-name="article.type_name"
                  :author-name="article.author_name"
                  :author-link="article.author_url"
                />
              </template>
            </div>
            <Pagination
              v-model="page"
              :per-page="perPage"
              :total="data.total"
            />
          </div>
        </div>
      </Container>
    </div>
  </main>
</template>

<script setup lang="ts">
const props = defineProps<{
  name?: string
  tid?: number
  description?: string
  biography?: string
}>()

const router = useRouter()
const route = useRoute()
const { $texts } = useNuxtApp()
const perPage = 20

const page = computed({
  get(): number {
    return parseInt(useQueryString('page').value) || 1
  },
  set(value: number) {
    if (value > 1) {
      router.push({
        query: {
          page: value,
        },
      })
    } else {
      router.push({
        query: {},
      })
    }
  },
})

// Get the data.
const { data, pending } = await useAsyncData(
  'author_query_' + route.fullPath,
  async () => {
    const variables = {
      author: '' + props.tid,
      limit: perPage,
      offset: 0,
    }
    variables.offset = page.value * perPage - perPage || 0
    return useGraphqlQuery('authorList', variables).then((v) => {
      return {
        articles: v.data?.results?.documents || [],
        numPages: Math.ceil((v.data?.results?.count || 0) / perPage),
        total: v.data?.results?.count,
      }
    })
  },
  {
    watch: [page],
  },
)

// After clicking on the page, scroll to top.
scrollOnQueryChange()
</script>
