<template>
  <div>
    <div v-if="showTitle" class="toc-title">{{ title }}</div>
    <nav class="toc" :class="hideNumberingClass">
      <ol>
        <template v-for="(entry, key) in toc">
          <li
            v-if="entry"
            :key="key"
            :class="{ 'is-active': isActive(entry?.url?.path) }"
          >
            <DrupalLink :to="entry?.url?.path">
              {{ overrideTitle(entry) }}
            </DrupalLink>
          </li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script setup lang="ts">
// Data
import type { SeriesFragment } from '#graphql-operations'

const route = useRoute()

// Props
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  hideNumbering: {
    type: Boolean,
    default: false,
  },
  showTitle: {
    type: Boolean,
    default: false,
  },
  toc: Object as PropType<SeriesFragment['toc']>,
})

// Computed
const hideNumberingClass = computed(() => {
  if (props.hideNumbering === true) {
    return 'hide-numbers'
  }
  return ''
})

// Methods
const isActive = function (path: string | undefined) {
  return route.path === path
}

const overrideTitle = function (entry: any) {
  return entry?.subtitle ? entry?.subtitle : entry?.title
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables';
.toc-title {
  margin: 0.75rem 0 0.25rem 0;
  font-size: 1rem;
  font-weight: $font-weight-semibold;
}
</style>
