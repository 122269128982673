<template>
  <div>
    <header class="hero">
      <div class="container">
        <div class="hero-content">
          <h1 class="h1">{{ name }}</h1>
          <TaxonomyTermStructureSearchBox :name="name" />
        </div>
      </div>
    </header>
    <div class="page-content">
      <Container>
        <div v-if="data" class="row row-reverse-md-down">
          <div class="col-lg-8 col-12">
            <div class="snippet-list">
              <template v-if="pending">
                <snippet-loading v-for="index in 10" :key="'loading' + index" />
              </template>
              <snippet
                v-for="(article, key) in data.articles"
                v-else
                :key="key"
                :to="article.url"
                :title="article.title"
                :passages="article.bible_passage"
                :subtitle="subtitle(article)"
                :text="article.snippet"
                :type="article.bundle"
                :type-name="article.type_name"
                :author-name="article.author_name"
                :author-link="article.author_url"
                :image="article.cover_image"
              />
            </div>
            <Pagination
              v-model="page"
              :per-page="perPage"
              :total="data.total"
            />
          </div>
          <div class="col-lg-4 col-12">
            <div
              v-if="description"
              class="content is-small mb-5"
              v-html="description"
            />
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ListItemFragment } from '#graphql-operations'

const props = defineProps<{
  name?: string
  tid?: number
  description?: string
}>()

const router = useRouter()

// Data
const perPage = 20

const page = computed({
  get(): number {
    return parseInt(useQueryString('page').value) || 1
  },
  set(value: number) {
    if (value > 1) {
      router.push({
        query: {
          page: value,
        },
      })
    } else {
      router.push({
        query: {},
      })
    }
  },
})

const route = useRoute()

// Get the data.
const { data, pending } = await useAsyncData(
  'list_query_' + route.fullPath,
  async () => {
    const variables = {
      category: '' + props.tid,
      limit: perPage,
      offset: 0,
    }
    variables.offset = page.value * perPage - perPage || 0
    return useGraphqlQuery('listSearch', variables).then((v) => {
      return {
        articles: v.data?.results?.documents || [],
        numPages: Math.ceil((v.data?.results?.count || 0) / perPage),
        total: v.data?.results?.count,
      }
    })
  },
  {
    watch: [page],
  },
)

// After clicking on the page, scroll to top.
scrollOnQueryChange()

const subtitle = function (article: ListItemFragment) {
  return article.article_subtitle
    ? article.article_subtitle
    : article.book_subtitle
}
</script>
