<template>
  <article-layout :title="title" :lead="subtitle">
    <template #before>
      <div class="article-layout-sidebar-item is-book-cover">
        <div class="book-cover is-large">
          <media-image :image="cover?.image" />
        </div>
      </div>
    </template>

    <template #author>
      <sidebar-author :author="author" />
    </template>

    <template #downloads>
      <sidebar-downloads
        :id="id"
        type="book"
        :title="$texts('downloads', 'Downloads')"
      />
    </template>

    <template v-if="audio && audio.length" #audios>
      <sidebar-audio :audios="audio" />
    </template>

    <template #default>
      <section>
        <h2 v-if="firstChapter" id="section-1" class="section-title-2">
          {{ firstChapter.title }}
          <a
            data-scroll=""
            href="#section-1"
            class="anchor"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Copy URL"
          >
            <HashIcon />
          </a>
        </h2>
        <div
          v-if="firstChapter && firstChapter.text"
          class="content"
          v-html="firstChapter.text"
        ></div>
      </section>
      <template v-if="firstChapter">
        <section
          v-for="subchapter in firstChapter.subchapters"
          :id="'paragraph-' + subchapter.id"
          :key="subchapter.id"
          ref="targets"
        >
          <h3 class="section-title">
            {{ subchapter.title }}
            <a
              :href="'#paragraph-' + subchapter.id"
              class="anchor"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Copy URL"
            >
              <HashIcon />
            </a>
          </h3>
          <div class="content" v-html="subchapter.text"></div>
        </section>
      </template>
    </template>

    <template #toc>
      <div class="sidebar-item-content">
        <div class="sidebar-label">
          {{ $texts('toc', 'Inhaltsverzeichnis') }}
        </div>
        <PublicationTableOfContents
          :toc="toc"
          :hide-numbering="hideNumbering"
        />
      </div>
    </template>
  </article-layout>
</template>

<script setup lang="ts">
import type { PublicationBookFragment } from '#graphql-operations'
import HashIcon from '~icons/bi/hash'

defineProps<{
  title?: string
  subtitle?: string
  id?: string
  hideNumbering?: boolean
  bookDescription?: string
  toc?: PublicationBookFragment['toc']
  author?: PublicationBookFragment['author']
  cover?: PublicationBookFragment['cover']
  imprint?: string
  firstChapter?: PublicationBookFragment['firstChapter']
  audio?: PublicationBookFragment['audio']
}>()

const targets = ref([])

const { $texts } = useNuxtApp()

provide('toc', targets)
</script>
