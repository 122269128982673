<template>
  <nav class="toc" :class="hideNumberingClass">
    <ol>
      <li
        v-for="(item, key) in toc"
        :key="key"
        :class="{ 'is-active': isActive(item?.url?.path || '') }"
      >
        <DrupalLink :to="item?.url?.path">
          {{ item.title }}
        </DrupalLink>

        <ol
          v-if="item.subchapters?.length"
          v-show="isActive(item?.url?.path || '')"
        >
          <li v-for="subchapter in item.subchapters" :key="subchapter.id">
            <DrupalLink
              :to="subchapter?.url?.path"
              :aria-current-value="`paragraph-${subchapter.id}` == activeId"
              :class="{
                'is-active': `paragraph-${subchapter.id}` == activeId,
              }"
              @click="setActive(`paragraph-${subchapter.id}`)"
            >
              {{ subchapter.title }}
            </DrupalLink>
          </li>
        </ol>
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type {
  ChapterChapterFragment,
  PublicationBookFragment,
} from '#graphql-operations'
import type { Targets } from 'vue-use-active-scroll'
import { useActiveScroll } from 'vue-use-active-scroll'

// Props
const props = defineProps({
  hideNumbering: {
    type: Boolean,
    default: false,
  },
  toc: Object as PropType<
    PublicationBookFragment['toc'] | ChapterChapterFragment['toc']
  >,
})

const route = useRoute()
const targets = inject<Targets>('toc') as any
const { setActive, activeId } = useActiveScroll(targets, {
  boundaryOffset: { toTop: 0, toBottom: 150 },
})

// Computed
const hideNumberingClass = computed(() => {
  if (props.hideNumbering === true) {
    return 'hide-numbers'
  }
  return ''
})

// Methods
const isActive = function (path: string) {
  return route.path.includes(path)
}
</script>
