<template>
  <BForm inline class="searchblock mt-3" @submit.prevent="onSubmit">
    <NuxtLink :to="searchUrl" class="btn btn-primary">
      {{ $texts('start_search', 'Suche starten') }}
    </NuxtLink>
  </BForm>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    default: '',
  },
})

// Computed
const searchUrl = computed(() => {
  return {
    name: 'search',
    query: {
      structure_names: [props.name],
    },
  }
})

const onSubmit = () => {
  return
}

const { $texts } = useNuxtApp()
</script>
