<template>
  <div class="book-recommendation">
    <a
      class="book-cover book-recommendation-image"
      :href="href"
      target="_blank"
    >
      <media-image :image="image" />
    </a>
    <h3 class="h4 mt-0 mb-0">{{ title }}</h3>
    <p v-if="authorName" class="mb-1">{{ authorName }}</p>

    <div class="content" v-html="text"></div>
    <a class="btn btn-primary btn-sm mt-1" :href="href" target="_blank">
      {{ $texts('online_shop', 'Zum Shop') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import type {
  BookRecommendationFragment,
  MediaImageBookcoverFragment,
} from '#graphql-operations'

const props = defineProps({
  nid: Number,
  title: String,
  text: String,
  link: Object as PropType<BookRecommendationFragment['link']>,
  media: Object as PropType<BookRecommendationFragment['media']>,
  author: Object as PropType<BookRecommendationFragment['author']>,
})

const { $texts } = useNuxtApp()

const image = computed(() => {
  return (props.media as MediaImageBookcoverFragment)?.image
})

const href = computed(() => {
  return props.link?.uri?.path || ''
})

const authorName = computed(() => {
  return props.author?.name || ''
})
</script>

<style lang="scss">
.book-recommendation {
  font-size: 1rem;
  margin-top: 1rem;

  ul {
    padding-left: 0.875rem;
  }
}

.book-recommendation-image {
  display: block;
  float: right;
  width: 6rem;
  margin-left: 1rem;
  margin-top: -2.25rem;
}
</style>
