<template>
  <article-layout :title="title" :lead="verses">
    <template #metadata>
      <sidebar-metadata v-if="metadata.length" :meta="metadata" />
    </template>

    <template #author>
      <sidebar-author
        v-if="author"
        :author="author"
        :title="$texts('author', 'Autor')"
      />
    </template>

    <template v-if="paragraphs && paragraphs.length" #default>
      <span v-for="paragraph in paragraphs" :key="paragraph.id">
        <h2 class="paragraph-header">{{ paragraph.title }}</h2>
        <a :id="`paragraph-` + paragraph.id" class="anchor"></a>
        <div v-html="paragraph.text"></div>
      </span>
    </template>

    <template #downloads>
      <sidebar-downloads
        :id="nid"
        :title="$texts('downloads', 'Downloads')"
        type="article"
      />
    </template>

    <template v-if="audio && audio.length" #audios>
      <sidebar-audio :audios="audio" />
    </template>

    <template v-if="collection" #collection>
      <sidebar-collection :collection="collection" />
    </template>

    <template v-if="toc && toc.length" #toc>
      <div class="sidebar-item-content">
        <div class="sidebar-label">
          {{ $texts('toc', 'Inhaltsverzeichnis') }}
        </div>
        <NodeArticleSeries
          :toc="toc"
          :title="tocTitle"
          :show-title="showTocTitle"
          :hide-numbering="hideNumbering"
        />
      </div>
    </template>

    <template v-if="hasCollection" #collectionSeries>
      <div class="sidebar-item-content">
        <div class="sidebar-label">
          {{ $texts('continuous_explanations', 'Weitere Erklärungen') }}
        </div>
        <NodeArticleCollectionToc
          v-if="passages && collection"
          :book="passages[0].book"
          :chapter="passages[0].chapter"
          :collection="collection.name"
        />
      </div>
    </template>

    <template v-if="videos && videos.length" #videos>
      <div class="sidebar-item-content">
        <div class="sidebar-label">video</div>
        <MediaVideoPreview
          v-for="(video, key) in videos"
          :key="key"
          :video="video"
        />
      </div>
    </template>

    <template #recommendation>
      <BookRecommendation :node-id="nid" :term-ids="termIds" />
    </template>
  </article-layout>
</template>

<script setup lang="ts">
import type { NodeArticleFragment } from '#graphql-operations'

const { $texts } = useNuxtApp()

const props = defineProps<{
  nid?: number
  title?: string
  subtitle?: string
  year?: number
  magazine?: number
  page?: number
  body?: string
  author?: NodeArticleFragment['author']
  recommendations?: NodeArticleFragment['recommendations']
  series?: NodeArticleFragment['series']
  passages?: NodeArticleFragment['passages']
  url?: NodeArticleFragment['url']
  collection?: NodeArticleFragment['collection']
  paragraphs?: NodeArticleFragment['paragraphs']
  videos?: NodeArticleFragment['videos']
  audio?: NodeArticleFragment['audio']
}>()

// Computed
const metadata = computed(() => {
  const data = []

  if (props.year) {
    data.push([$texts('year', 'Jahr'), props.year])
  }

  if (props.magazine) {
    data.push([$texts('magazine', 'Heft'), props.magazine])
  }

  if (props.page) {
    data.push([$texts('page', 'Seite'), props.page])
  }

  return data.map(([label, value]) => ({ label, value }))
})

const termIds = computed(() => {
  const ids = props.recommendations?.map((item) => {
    return item?.id
  })

  return ids?.join('+')
})

const verses = computed(() => {
  const verses = props.passages?.map((item) => {
    return item?.processed
  })
  const separator = $texts('verse_separator', '; ')
  const verseList = verses?.join(separator) || ''

  if (verseList) {
    return verseList
  }

  // Fallback subtitle
  if (props.subtitle) {
    return props.subtitle
  }

  return ''
})

const toc = computed(() => {
  if (props.series) {
    return props.series.toc?.map((item) => {
      return item
    })
  }
  return []
})

const hasCollection = computed(() => {
  return !!(
    props.collection &&
    props.collection.name &&
    props.passages &&
    props.passages[0] &&
    props.passages[0].book &&
    props.passages[0].chapter
  )
})

const tocTitle = computed(() => {
  if (props.series) {
    return props.series?.title
  }
  return ''
})

const showTocTitle = computed(() => {
  if (props.series) {
    return props.series?.showTitle
  }
  return false
})

const hideNumbering = computed(() => {
  if (props.series) {
    return props.series?.hideNumbering
  }
  return false
})
</script>

<style scoped lang="scss">
.details {
  display: flex;
  justify-content: space-between;
}

.video-responsive {
  box-shadow: 0px 0px 15px 0px rgba(#000, 0.05);
  margin-bottom: 2rem;
}
</style>
