<template>
  <article-layout :title="title">
    <template #author>
      <sidebar-author
        v-if="author"
        :author="author"
        :title="$texts('author', 'Autor')"
      />
    </template>

    <template #default>
      <div class="book-recommendation">
        <a
          class="book-cover book-recommendation-image"
          :href="href"
          target="_blank"
        >
          <media-image :image="image" />
        </a>
        <h3 class="h4 mt-0 mb-0">{{ title }}</h3>
        <p v-if="authorName" class="mb-1">{{ authorName }}</p>

        <div class="content" v-html="body"></div>
        <a class="btn btn-primary btn-sm mt-1" :href="href" target="_blank">
          {{ $texts('online_shop', 'Zum Shop') }}
        </a>
      </div>
      <template v-if="tags">
        <h4 class="mt-5">
          {{ $texts('related_terms_book_recomentdation', 'Begriffe') }}
        </h4>
        <div class="content">
          <ul>
            <li v-for="tag in tags" :key="tag.tid">
              <DrupalLink :to="tag?.url?.path">
                {{ tag.name }}
              </DrupalLink>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="articles">
        <h4 class="mt-5">
          {{
            $texts('related_articles_book_recomentdation', 'Verwandte Artikel')
          }}
        </h4>
        <div class="content">
          <ul>
            <li v-for="article in articles" :key="article.nid">
              <DrupalLink :to="article?.url?.path">
                {{ article.title }}
              </DrupalLink>
            </li>
          </ul>
        </div>
      </template>
    </template>
  </article-layout>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type {
  MediaImageBookcoverFragment,
  NodeBookRecommendationFragment,
} from '#graphql-operations'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  body: String,
  articles: Object as PropType<NodeBookRecommendationFragment['articles']>,
  author: Object as PropType<NodeBookRecommendationFragment['author']>,
  tags: Object as PropType<NodeBookRecommendationFragment['tags']>,
  link: Object as PropType<NodeBookRecommendationFragment['link']>,
  cover: Object as PropType<NodeBookRecommendationFragment['cover']>,
  media: Object as PropType<NodeBookRecommendationFragment['media']>,
})

const image = computed(() => {
  return (props.media as MediaImageBookcoverFragment)?.image
})

const href = computed(() => {
  return props.link?.uri?.path || ''
})

const authorName = computed(() => {
  return props.author?.name || ''
})
</script>

<style scoped lang="scss">
.intro {
  font-weight: bold;
  line-height: 1.25;
  font-style: italic;
}

.book-recommendation {
  margin-top: 0;
}

.book-recommendation-image {
  float: none;
  margin: 0 0 1.5rem 0;
}
</style>
