<template>
  <div class="sidebar-item-content audio">
    <div class="sidebar-label">{{ $texts('audio', 'Audio') }}</div>
    <div class="audios">
      <div v-for="audio in audios" :key="audio.id" class="element">
        <audio v-if="audio" controls>
          <source
            :src="audio.file?.entity?.fullUrl"
            :type="audio.file?.entity?.filemime"
          />
        </audio>
        <div v-if="audio.name" class="title">
          {{ audio.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NodeArticleFragment } from '#graphql-operations'

const { $texts } = useNuxtApp()

defineProps<{
  audios?: NodeArticleFragment['audio']
}>()
</script>

<style lang="scss" scoped>
.audio {
  .sidebar-label {
    margin-bottom: 1rem;
  }

  .audios {
    .element {
      margin-bottom: 0.5rem;
    }
    .title {
      font-size: 1rem;
    }
  }
}
</style>
