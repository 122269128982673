export default function () {
  const route = useRoute()
  watch(
    () => route.fullPath,
    () => {
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      })
    },
  )
}
