<template>
  <div>
    <header class="hero has-sidebar-spacing">
      <div class="container">
        <slot name="heroAfter"></slot>
        <div class="hero-content">
          <h1 class="h1">{{ title }}</h1>
        </div>
      </div>
    </header>
    <div class="page-content">
      <Container>
        <div class="row content">
          <div class="col-lg-8 col-12">
            <div v-if="body" v-html="body"></div>
          </div>
          <div class="col-lg-4 col-12"></div>
        </div>
        <div v-if="entity" class="mt-4 col-lg-8 col-12">
          <Webform
            :webform="entity"
            :source-entity-id="sourceEntityId"
            :source-entity-type="sourceEntityType"
          />
        </div>
      </Container>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NodeWebformFragment } from '#graphql-operations'

const props = defineProps<{
  nid: number
  title?: string
  body?: string
  webform?: NodeWebformFragment['webform']
}>()

const { data: entity } = await useAsyncData(async () => {
  return useGraphqlQuery('webform', {
    id: props.webform?.id || '',
  }).then((v) => v.data.webform)
})

const sourceEntityType = 'node'
const sourceEntityId = `${props.nid}`
</script>
