<template>
  <div class="sidebar-item-content is-flex">
    <div class="sidebar-label">{{ title }}</div>
    <div class="btn-group">
      <a :href="pdfLink" class="sidebar-label"> <IconPrinter /> PDF </a>
      <a
        v-if="type === 'book'"
        :href="epubLink"
        class="sidebar-label ms-2"
        target="_blank"
      >
        <IconBook /> EPUB
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconBook from '~icons/bi/book'
import IconPrinter from '~icons/bi/printer'

const language = useCurrentLanguage()

// Props
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  id: {
    type: [Number, String],
    default: '',
  },
  type: {
    type: String,
    default: 'article',
  },
})

const pdfLink = computed(() => {
  return '/' + language.value + '/pdf/' + props.type + '/' + props.id
})

const epubLink = computed(() => {
  return '/' + language.value + '/epub/' + props.type + '/' + props.id
})
</script>
