<template>
  <div v-if="collection" class="sidebar-item-content is-flex">
    <div class="sidebar-label">{{ $texts('collection', 'Sammulung') }}</div>
    <div class="sidebar-label normal">{{ collection.name }}</div>
  </div>
</template>

<script setup lang="ts">
import type { NodeArticleFragment } from '#graphql-operations'

const { $texts } = useNuxtApp()

// Props
defineProps<{
  collection?: NodeArticleFragment['collection']
}>()
</script>
