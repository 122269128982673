<template>
  <NodePage v-if="entity && pageType === 'NodePage'" v-bind="entity" />
  <NodeNews v-else-if="entity && pageType === 'NodeNews'" v-bind="entity" />
  <NodeArticle
    v-else-if="entity && pageType === 'NodeArticle'"
    v-bind="entity"
  />
  <NodeWebform
    v-else-if="entity && pageType === 'NodeWebform'"
    v-bind="entity"
  />
  <NodeBookRecommendation
    v-else-if="entity && pageType === 'NodeBookRecommendation'"
    v-bind="entity"
  />
  <ChapterChapter
    v-else-if="entity && pageType === 'ChapterChapter'"
    v-bind="entity"
  />
  <PublicationBook
    v-else-if="entity && pageType === 'PublicationBook'"
    v-bind="entity"
  />
  <PublicationSeries
    v-else-if="entity && pageType === 'PublicationSeries'"
    v-bind="entity"
  />
  <TaxonomyTermStructure
    v-else-if="entity && pageType === 'TaxonomyTermStructure'"
    v-bind="entity"
  />
  <TaxonomyTermAuthor
    v-else-if="entity && pageType === 'TaxonomyTermAuthor'"
    v-bind="entity"
  />
  <TaxonomyTermTags
    v-else-if="entity && pageType === 'TaxonomyTermTags'"
    v-bind="entity"
  />
</template>

<script lang="ts" setup>
import type {
  ChapterChapterFragment,
  EntityUrlFragment,
  NodeArticleFragment,
  NodeBookRecommendationFragment,
  NodeNewsFragment,
  NodePageFragment,
  NodeWebformFragment,
  PublicationBookFragment,
  PublicationSeriesFragment,
  TaxonomyTermAuthorFragment,
  TaxonomyTermStructureFragment,
  TaxonomyTermTagsFragment,
} from '#graphql-operations'

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
  // This is explicitly without a language prefix, then we can support redirects
  languageMapping: {
    de: '/:slug(.*)*',
    es: '/es/:slug(.*)*',
  },
})

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => {
    return v.data
  })
})

const pageType = computed(() => {
  const route = query?.value?.route as EntityUrlFragment
  return route.entityGlobal?.__typename
})

const { entity } = await useDrupalRoute<
  | NodePageFragment
  | NodeNewsFragment
  | NodeArticleFragment
  | NodeWebformFragment
  | NodeBookRecommendationFragment
  | PublicationBookFragment
  | PublicationSeriesFragment
  | ChapterChapterFragment
  | TaxonomyTermStructureFragment
  | TaxonomyTermAuthorFragment
  | TaxonomyTermTagsFragment
>(query.value)

setBreadcrumbLinksFromRoute(query.value)
await renderPageDependencies()
</script>
