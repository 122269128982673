<template>
  <article-layout :title="book?.title" :lead="subtitle">
    <template v-if="book" #author>
      <sidebar-author :author="book.author" />
    </template>

    <template v-if="book" #downloads>
      <sidebar-downloads
        :id="book.id"
        type="book"
        :title="$texts('downloads', 'Downloads')"
      />
    </template>

    <template #default>
      <h2 id="section-1" class="section-title-2">
        {{ title }}
        <a
          data-scroll=""
          href="#section-1"
          class="anchor"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Copy URL"
        >
          <HashIcon />
        </a>
      </h2>
      <div v-if="text" class="content" v-html="text"></div>
      <section
        v-for="subchapter in subchapters"
        :id="'paragraph-' + subchapter.id"
        :key="subchapter.id"
        ref="targets"
      >
        <h3 class="section-title">
          {{ subchapter.title }}
          <a
            :href="'#paragraph-' + subchapter.id"
            class="anchor"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Copy URL"
          >
            <HashIcon />
          </a>
        </h3>
        <div class="content" v-html="subchapter.text"></div>
      </section>
    </template>

    <template #toc>
      <div class="sidebar-item-content">
        <div class="sidebar-label">
          {{ $texts('toc', 'Inhaltsverzeichnis') }}
        </div>
        <PublicationTableOfContents :toc="toc" :hide-numbering="hideNumbers" />
      </div>
    </template>
  </article-layout>
</template>

<script setup lang="ts">
import HashIcon from '~icons/bi/hash'
import type { ChapterChapterFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  id?: string
  text?: string
  passages?: ChapterChapterFragment['passages']
  subchapters?: ChapterChapterFragment['subchapters']
  toc?: ChapterChapterFragment['toc']
  book?: ChapterChapterFragment['book']
}>()

const { $texts } = useNuxtApp()

const targets = ref([])
provide('toc', targets)

const subtitle = computed(() => {
  return verses.value || props.book?.subtitle
})

const hideNumbers = computed(() => {
  return props.book?.hideNumbering || false
})

const verses = computed(() => {
  const verses =
    props.passages?.map((item) => {
      return item?.processed
    }) || []
  const separator = $texts('verse_separator', '; ')
  return verses.join(separator) || ''
})
</script>
