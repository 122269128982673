<template>
  <nav v-if="data && data.tocEntries.length > 1" class="toc unordered">
    <ul>
      <li
        v-for="item in data.tocEntries"
        :key="item.nid"
        :class="{ 'is-active': isActive(item.url) }"
      >
        <DrupalLink :to="item.url">
          <div v-if="item.bible_passage" class="passage">
            {{ formatPassage(item.bible_passage) }}:
          </div>
          <span class="title">{{ item.title }}</span>
        </DrupalLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  book: Number,
  chapter: Number,
  collection: String,
})

// Get the data.
const { data } = await useAsyncData(async () => {
  const variables = {
    book: props.book?.toString() || '',
    chapter: props.chapter?.toString() || '',
    collection: props.collection || '',
  }
  return useGraphqlQuery('collectionToc', variables).then((v) => {
    return {
      tocEntries: v.data?.query?.results || [],
    }
  })
})

const route = useRoute()
const isActive = (path: string | undefined) => {
  path = path || ''
  return route.path === path
}

const formatPassage = (biblePassage: string | string[]) => {
  let passage = ''
  if (biblePassage.length > 0) {
    if (biblePassage[0]) {
      passage = biblePassage[0]
    }

    if (biblePassage[1]) {
      passage = passage + ' - ' + biblePassage[1]
    }
  }

  return passage
}
</script>

<style lang="scss">
.toc {
  &.unordered {
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        padding-left: 0;
        &::before {
          content: '';
        }

        a {
          padding-left: 0.5rem;

          .passage {
            font-style: italic;
          }
        }
      }
    }
  }
}
</style>
