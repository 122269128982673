<template>
  <article-layout
    :title="title"
    :lead="$texts('article_series', 'Artikelserie')"
  >
    <template #default>
      <section>
        <div class="content">
          <h2>{{ $texts('related_articles', 'Artikelliste') }}</h2>
          <nav class="article_series" :class="hideNumberingClass">
            <ol>
              <template v-for="(entry, key) in articles">
                <li v-if="entry" :key="key">
                  <DrupalLink :to="entry?.url?.path">
                    {{ overrideTitle(entry) }}
                  </DrupalLink>
                </li>
              </template>
            </ol>
          </nav>
        </div>
      </section>
    </template>
  </article-layout>
</template>

<script setup lang="ts">
import type { PublicationSeriesFragment } from '#graphql-operations'

const props = defineProps({
  __typename: String,
  id: String,
  title: String,
  hideNumbering: Boolean,
  showTitle: Boolean,
  bookDescription: String,
  articles: Object as PropType<PublicationSeriesFragment['articles']>,
})

const targets = ref([])

const { $texts } = useNuxtApp()

// Computed
const hideNumberingClass = computed(() => {
  if (props.hideNumbering === true) {
    return 'hide-numbers'
  }
  return ''
})

const overrideTitle = function (entry: any) {
  return entry?.subtitle ? entry?.subtitle : entry?.title
}

provide('toc', targets)
</script>

<style lang="scss" scoped>
.article_series {
  ol {
    list-style: none;
    counter-reset: item;
    padding-left: 0;
    ol {
      padding-left: 1.5rem;
      li {
        a {
          padding-left: 2rem;
        }
        &::before {
          width: 2rem;
        }
      }
    }
  }
  padding: 0;

  li {
    position: relative;
    counter-increment: item;
    a {
      display: block;
      padding: 0;
      position: relative;
      padding-left: 2rem;
    }

    &::before {
      content: counters(item, '.');
      display: inline-block;
      width: 1.75rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 0 0 0.5em;
    }
  }

  &.hide-numbers {
    li {
      &::before {
        content: '';
      }
      a {
        padding-left: 0;
      }
    }
  }
}
</style>
