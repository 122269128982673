<template>
  <div v-if="name && href" class="sidebar-item-content is-flex">
    <div class="sidebar-label">{{ title }}</div>
    <DrupalLink :to="href" class="sidebar-label">{{ name }}</DrupalLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { NodeArticleFragment } from '#graphql-operations'

const { $texts } = useNuxtApp()

const props = defineProps<{
  author?: NodeArticleFragment['author']
}>()

// Computed
const name = computed(() => {
  return props.author?.name
})

const title = computed(() => {
  return $texts('author', 'Autor')
})

const href = computed(() => {
  return props.author?.url?.path
})
</script>
