<template>
  <div class="mb-3">
    <div class="play-image">
      <BImg
        :src="video.thumbnail?.url?.urlPath"
        fluid
        :alt="video.title"
        :title="video.title"
        @click="show = !show"
      />
      <IconYoutube @click="show = !show" />
    </div>
  </div>
  <BModal
    v-if="show"
    v-model="show"
    centered
    fullscreen
    cancel-disabled
    ok-only
    :ok-title="$texts('close', 'Schliessen')"
    @hidden="onClose"
  >
    <MediaRemoteVideo :video="video" />
  </BModal>
</template>

IconYoutube

<script setup lang="ts">
import IconYoutube from '~icons/bi/youtube'

const show = ref(false)
const { $texts } = useNuxtApp()
defineProps({
  video: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const onClose = () => {}
</script>

<style scoped lang="scss">
@import '~/assets/scss/variables';
.play-image {
  position: relative;
}
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  transition: 0.3s ease;
  color: $black;
  fill: $black;
  transform: translate(-50%, -50%);
  outline: none !important;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 1;
    color: $red;
  }
}
</style>
