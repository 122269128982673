<template>
  <BImg
    :src="url"
    fluid
    :alt="alt"
    :title="title"
    :width="width"
    :height="height"
  ></BImg>
</template>

<script setup lang="ts">
const props = defineProps({
  image: {
    type: Object as any,
    default: () => null,
  },
})

const width = computed(() => {
  if (props.image?.url) {
    return props.image?.url?.width || ''
  }
  return props.image?.width || ''
})

const height = computed(() => {
  if (props.image?.url) {
    return props.image?.url?.height || ''
  }
  return props.image?.height || ''
})

const url = computed(() => {
  return props.image?.url?.url || ''
})
const alt = computed(() => {
  return props.image?.alt || ''
})
const title = computed(() => {
  return props.image?.title || ''
})
</script>
